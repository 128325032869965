import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  SubmitButton,
} from "@bleu/ui";
import React, { useEffect } from "react";
import { useActionData } from "react-router-dom";

export function ResetActivities({ handleSubmit }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const actionData = useActionData() as {
    success: boolean;
    intent: string;
    data: {
      [key: string]: string;
    };
  };

  useEffect(() => {
    if (actionData && !actionData?.data?.error) {
      setIsOpen(false);
    }
  }, [actionData]);

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        <Button type="button" className="text-sm" size="sm">
          Reset Activities
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This will reset all activities for this participant. This can not be
            undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <SubmitButton type="button" onClick={handleSubmit}>
            Reset
          </SubmitButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
