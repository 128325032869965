import { SWRDataTable } from "@bleu/ui";
import React, { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useSWRConfig } from "swr";

function ParticipantActionsTable() {
  const { end_user_id } = useParams();
  const data = useOutletContext() as {
    actionData: {
      success: boolean;
      intent: string;
      data: {
        [key: string]: string;
      };
    };
  };

  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (data?.actionData?.success === true) {
      mutate(() => true, undefined, {
        revalidate: true,
      });
    }
  }, [data]);

  return (
    <>
      <SWRDataTable
        searchKey="title"
        fetchPath={`/admin/v2/participants/${end_user_id}/participant_actions`}
      />
    </>
  );
}

export default ParticipantActionsTable;
