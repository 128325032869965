import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  Form,
  toast,
} from "@bleu/ui";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { buildForm } from "@utils/buildForm";
import { serialize } from "object-to-formdata";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { json, useActionData, useSubmit } from "react-router-dom";

const action = async ({ request }) => {
  const formData = await request.formData();
  const response = await fetch("/participants/accounts/update_email_settings", {
    method: "POST",
    body: formData,
  });
  const data = await response.json();

  if (data?.success) {
    location.reload();
    return json({ success: true });
  } else {
    toast({
      title: "An error occurred.",
      description: data.errors,
      variant: "destructive",
    });
    return json({ success: false });
  }
};

function EditEmailSettings({ program, participant }) {
  const actionData = useActionData() as { success: boolean };
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    if (actionData?.success) {
      setIsOpen(false);
    }
  }, [actionData]);

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
  const csrfToken = ReactOnRails.authenticityToken();

  const form = useForm({
    defaultValues: {
      email_setting_id: participant.email_setting_id || "",
    },
  });

  const submit = useSubmit();

  async function onSubmit() {
    const isValid = await form.trigger();
    if (!isValid) return;

    const values = form.getValues();

    const formData = serialize(values);
    formData.append("authenticity_token", csrfToken);
    submit(formData, { method: "post", encType: "multipart/form-data" });
  }

  const FIELDS = [
    {
      name: "email_setting_id",
      type: "radio_item",
      label: "",
      options: program.email_settings.map((setting) => ({
        label: setting.description,
        value: setting.id,
      })),
      required: true,
      layout: "stack",
    },
    {
      name: "participant_id",
      type: "hidden",
      value: participant.id,
    },
  ];

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          size="sm"
          className="bg-transparent ml-auto h-8 shadow-none border focus:outline-perk-primary"
        >
          <Pencil1Icon className="size-4 text-perk-primary cursor-pointer" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[550px] gap-y-0">
        <DialogTitle>Edit Email Settings</DialogTitle>
        <DialogDescription className="text-xs mb-0">
          Essential platform emails cannot be disabled, but you can manage your
          preferences for promotional communications.
        </DialogDescription>
        <Form
          {...form}
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-4"
        >
          <div>{buildForm(FIELDS, form)}</div>
          <Button
            type="submit"
            className="w-full bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 focus:outline-none focus:ring-0"
          >
            Save
          </Button>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

EditEmailSettings.action = action;
export default EditEmailSettings;
