import React from "react";

import { ChallengeData } from "./types";

export function FooterButton({
  data,
  completedCondition,
  handleSubmit,
}: {
  data: ChallengeData;
  completedCondition: boolean;
  handleSubmit?: () => void;
}) {
  const isCallToAction = data?.challenge_type === "call_action";
  const isApiCompletionOnly = data?.api_completion_only;
  async function onClick() {
    if (handleSubmit && !completedCondition) {
      if (!isCallToAction || (isCallToAction && !isApiCompletionOnly)) {
        await handleSubmit();
      }
    }
    if (!completedCondition && data?.link_url) {
      window.open(data.link_url, "_blank");
      if (isCallToAction && isApiCompletionOnly) {
        window.location.href = "/";
      }
    } else if (completedCondition) {
      window.location.href = "/";
    }
  }
  return (
    <button className="custom_btn zoom_btn" onClick={onClick}>
      {completedCondition ? "Earn More" : data.cta_button_text}
    </button>
  );
}
