import { BaseField, Button, SectionTitle, SWRDataTable, toast } from "@bleu/ui";
import Link from "@components/Link";
import { SettingsCard } from "@pages/admin/organization/programs/settings/(components)/SettingsCard";
import { PlusIcon } from "@radix-ui/react-icons";
import { client } from "@utils/api-client";
import * as React from "react";
import {
  json,
  redirect,
  redirectDocument,
  useLoaderData,
  useParams,
} from "react-router-dom";

import { detailsPageBuilder } from "./detailsPage";

export enum AdminPagePath {
  PROFILE_ATTRIBUTES = "/admin/v2/programs/:program_id/settings/program/profile-attributes",
  PROGRAM_PAGES = "/admin/v2/programs/:program_id/settings/program/program-pages",
  WEBHOOKS = "/admin/v2/programs/:program_id/settings/integrations/webhooks",
  PARTICIPANT_PROFILE = "/admin/v2/programs/:program_id/users/:participant_id/attributes",
}

export type FieldsLoaderData = {
  data: {
    fields: Array<BaseField>;
    defaultValues: Record<string, string>;
  };
};

type RedirectType = true | false | ((data: unknown) => string);

export function replaceParams(path: string, params: Record<string, string>) {
  return path.replace(/:\w+/g, (key) => {
    const keyName = key.replace(":", "");
    const id = params[keyName];
    if (!id) {
      throw new Error(`Missing param ${keyName}`);
    }
    return id;
  });
}

export const generateLoader = (path: string) => {
  return async ({ params }) => {
    const normalizedPath = replaceParams(path, params);

    const { data } = await client(normalizedPath, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return json({ data });
  };
};

export const generateAction =
  (
    path: string,
    modelName: string,
    mode: "create" | "update",
    shouldRedirect: RedirectType = true,
  ) =>
  async ({ params, request }) => {
    const formData = await request.formData();
    const normalizedPath = replaceParams(path, params);

    const method = mode === "create" ? "POST" : "PUT";
    const data = await client(normalizedPath, { body: formData, method });

    toast({
      title: data?.success ? "Sucess!" : "Error!",
      description: data?.success
        ? `${modelName} was ${mode === "create" ? "created" : "updated"} successfully!`
        : data?.errors,
      variant: data?.success ? "default" : "destructive",
    });

    if (data?.success && shouldRedirect === true) {
      const to =
        mode === "create" ? path : path.split("/").slice(0, -1).join("/");

      return redirect(replaceParams(to, params));
    }

    if (data?.success && typeof shouldRedirect === "function") {
      const to = replaceParams(shouldRedirect(data), params);
      if (to === location.pathname) return redirectDocument(to);

      return redirect(to);
    }

    return json({ data });
  };

interface BasePageProps {
  modelName: string;
  actionPath: string;
  title: string;
}

function BasePage({ actionPath, title }: Omit<BasePageProps, "modelName">) {
  const { data } = useLoaderData() as FieldsLoaderData;

  return (
    <div className="my-8 space-y-4">
      <SettingsCard
        defaultValues={data.defaultValues}
        fields={data.fields}
        title={title}
        action={{ path: actionPath }}
      />
    </div>
  );
}

function NewPage({ modelName, actionPath }: Omit<BasePageProps, "title">) {
  return <BasePage actionPath={actionPath} title={`Create ${modelName}`} />;
}

function EditPage({ modelName, actionPath }: Omit<BasePageProps, "title">) {
  const params = useParams();

  const path = replaceParams(actionPath, params);

  return <BasePage actionPath={path} title={`${modelName}`} />;
}

export const buildNewPage = ({
  modelName,
  actionPath,
  loaderPath,
  redirectAfterAction = true,
}: {
  modelName: string;
  actionPath: string;
  loaderPath: string;
  redirectAfterAction?: RedirectType;
}) => {
  return {
    loader: generateLoader(loaderPath),
    action: generateAction(
      actionPath,
      modelName,
      "create",
      redirectAfterAction,
    ),
    element: <NewPage modelName={modelName} actionPath={actionPath} />,
  };
};

export const buildEditPage = ({
  modelName,
  actionPath,
  loaderPath,
  redirectAfterAction = true,
}: {
  modelName: string;
  actionPath: string;
  loaderPath: string;
  redirectAfterAction?: RedirectType;
}) => {
  return {
    loader: generateLoader(loaderPath),
    action: generateAction(
      actionPath,
      modelName,
      "update",
      redirectAfterAction,
    ),
    element: <EditPage modelName={modelName} actionPath={actionPath} />,
  };
};

export const ShowPage = ({
  children,
  modelName,
}: {
  children?: React.ReactNode;
  modelName: string;
}) => {
  // @ts-ignore-next-line
  const { data } = useLoaderData();

  return (
    <div className="max-w-6xl w-full mx-auto bg-background">
      <h2 className="py-4 text-2xl font-bold text-foreground/80 ml">
        {modelName} - Detalhes
      </h2>
      <div className="mb-12 p-4 rounded-md shadow-lg border border-primary border-dashed">
        <div className="flex w-full">
          <div className="w-2/3 flex flex-col gap-2 space-y-4 rounded-md p-5 bg-background text-sm font-light">
            {detailsPageBuilder(data)}
          </div>
          <div className="w-1/3">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const buildShowPage = (modelName: string, loaderPath: string) => {
  return {
    loader: generateLoader(loaderPath),
    element: <ShowPage modelName={modelName} />,
  };
};

export const ADMIN_PAGES = [];

export const Dashboard = ({ fetchPath, action, searchKey }) => {
  const params = useParams();
  const normalizedPath = replaceParams(fetchPath, params);

  return (
    <SWRDataTable
      fetchPath={normalizedPath}
      action={action}
      searchKey={searchKey}
    />
  );
};

export const IndexPage = ({ modelName, fetchPath, action, searchKey }) => {
  return (
    <div>
      <div className="mb-10">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <SectionTitle>
              <span className="font-title">{modelName}</span>
            </SectionTitle>
            <p className="text-muted-foreground">
              Here is a list of {modelName.toLocaleLowerCase()}.
            </p>
          </div>
        </div>
        <Dashboard
          fetchPath={fetchPath}
          action={action}
          searchKey={searchKey}
        />
      </div>
    </div>
  );
};

export const buildIndexPage = ({
  modelName,
  fetchPath,
  action,
  searchKey,
  Component = IndexPage,
}: {
  modelName: string;
  fetchPath: string;
  action: unknown;
  searchKey: string;
  predicate?: (user) => boolean;
  Component?: React.ComponentType<{
    modelName: string;
    fetchPath: string;
    action: unknown;
    searchKey: string;
  }>;
}) => {
  return {
    element: (
      <Component
        modelName={modelName}
        fetchPath={fetchPath}
        action={action}
        searchKey={searchKey}
      />
    ),
  };
};

export const AddResourceButton = ({ text }) => (
  <Button size="sm" className="ml-auto hidden h-8 lg:flex">
    <PlusIcon className="mr-2 size-4" />
    {text}
  </Button>
);

const IndexPageActionButton = ({ link, text, setParams = false }) => {
  const params = useParams();

  return (
    <Link to={setParams ? replaceParams(link, params) : link}>
      <AddResourceButton text={text} />
    </Link>
  );
};

export const buildIndexPageActionButton = ({
  link,
  text,
  setParams = false,
}) => {
  return (
    <IndexPageActionButton link={link} text={text} setParams={setParams} />
  );
};
